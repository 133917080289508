import { Button, Flex, FormControl, FormLabel, HStack, Input, PinInput, PinInputField, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { STATUS } from "../../Constant";
import { useLoginStore } from "../../store/Login";
import { useNavigate } from "react-router-dom";

export const ChangeMPin = () => {
    const navigate = useNavigate()
    const [inputValue, setInputValue] = useState({})

    const { changePinAction, changePinStatus, resetPinStatus } = useLoginStore(s => ({
        changePinAction: s.changePinAction,
        changePinStatus: s.changePinStatus,
        resetPinStatus: s.resetPinStatus
    }))

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }

    const submitData = (e) => {
        e.preventDefault()
        changePinAction(inputValue)
    }

    useEffect(() => {
        if (changePinStatus === STATUS.SUCCESS) {
            resetPinStatus()
            setInputValue({
                oldMpin: "",
                newMpin: ""
            })
            navigate("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changePinStatus, resetPinStatus])

    return (
        <Flex w={"100%"} h={"100%"} flexDir={"column"} align={"center"} bg={"defaultDark"} color={"defaultWhite"}>
            <Flex w={{ base: "95%", md: "80%", xl: "50%" }} flexDir={"column"} p={{ base: 2, md: 5 }} >
                <Text my={5} fontSize={20} fontWeight={"semibold"}>Change MPin</Text>

                <form onSubmit={submitData}>
                    <FormControl isRequired>
                        <Flex flexDir={{ base: "column", md: "row" }} py={5} align={{ base: "start", md: "center" }}>
                            <FormLabel w={{ base: "40%", lg: "30%" }}>Old MPin</FormLabel>
                            <HStack >
                                <PinInput w={"100%"} otp autoFocus focusBorderColor={"blue.400"} borderColor={"gray.400"} value={inputValue.oldMpin} onChange={(e) => inputHandler("oldMpin", e)}>
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                </PinInput>
                            </HStack>
                        </Flex>
                    </FormControl>
                    <FormControl isRequired>
                        <Flex flexDir={{ base: "column", md: "row" }} py={5} align={{ base: "start", md: "center" }}>
                            <FormLabel w={{ base: "40%", lg: "30%" }}>New MPin</FormLabel>
                            <HStack>
                                <PinInput w={"100%"} otp autoFocus focusBorderColor={"blue.400"} borderColor={"gray.400"} value={inputValue.newMpin} onChange={(e) => inputHandler("newMpin", e)}>
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                    <PinInputField borderColor={"gray.400"} />
                                </PinInput>
                            </HStack>
                        </Flex>
                    </FormControl>
                    <Flex mt={5} justifyContent={"space-between"}>
                        <Button size={"sm"} colorScheme={"blue"} type="submit" isLoading={changePinStatus === STATUS.FETCHING}>Update</Button>
                    </Flex>
                </form>
            </Flex>
        </Flex >
    )
}