import React, { useEffect } from "react";
import { Box, Button, Flex, FormControl, HStack, Image, Input, PinInput, PinInputField, Text } from "@chakra-ui/react"
import { useState } from "react"
import { useLoginStore } from "../store/Login";
import { STATUS } from "../Constant";
import { getLocalStorageItem, setLocalStorageItem } from "../utils/LocalStorage";
import { useNavigate } from "react-router-dom";

export const LoginUser = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const accessToken = getLocalStorageItem("accessToken")
        if (accessToken) {
            navigate("/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { loginUserAction, userLoginStatus, userLogin, verifyOtpAction, verifyOtpStatus, resetStatus } = useLoginStore(s => ({
        loginUserAction: s.loginUserAction,
        userLoginStatus: s.userLoginStatus,
        userLogin: s.userLogin,
        verifyOtpAction: s.verifyOtpAction,
        verifyOtpStatus: s.verifyOtpStatus,
        resetStatus: s.resetStatus
    }))
    const [inputValue, setInputValue] = useState({ email: "", password: "" })

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }

    const submitLogin = (e) => {
        e.preventDefault()
        loginUserAction(inputValue)
    }

    useEffect(() => {
        if (userLoginStatus === STATUS.SUCCESS) {
            resetStatus()
            setLocalStorageItem("user", userLogin)
            setLocalStorageItem("accessToken", userLogin.accessToken)
            window.location.reload()
            console.log("navigate to");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetStatus, userLogin, userLoginStatus])

    return (
        <Flex w={"100vw"} h={"100vh"} justify={"center"} align={"center"} bgGradient={"linear-gradient(to bottom right, blue.50, blue.500)"}>
            <Flex w={"80%"} h={{ base: "90%", lg: "80%" }} borderRadius={20}>
                <Flex display={{ base: "none", lg: "initial" }} flexDir={"column"} w="50%" h={"100%"} bg={"blue.100"} borderLeftRadius={20} align={"center"} justify={"center"}>
                    <Image w={"100%"} h={"100%"} src={"/assets/V_PAY_LOGO.png"} alt="Banner" borderLeftRadius={20} />
                </Flex>
                <Box w={{ base: "100%", lg: "50%" }} h={"100%"} align={"center"} bg={{ base: "white", lg: "gray.50" }} borderLeftRadius={{ base: 20, lg: 0 }} borderRightRadius={20} px={{ base: 10, xl: 20 }} py={{ base: 5, xl: 10 }} justify={"center"}>
                    <Flex flexDir={"column"} w={"100%"} h={"100%"} align={"start"} justify={"center"}>
                        <Flex w={"100%"} display={{ base: "initial", lg: "none" }} justify={"center"} h={"80px"}>
                            <Image mb={4} w={{ base: "70%", md: "40%" }} h={"80px"} src={"/assets/logo1.png"} alt="Banner" />
                        </Flex>
                        <Text w={"100%"} textAlign={"center"} fontSize={{ base: 20, lg: 26 }} fontWeight={"bold"}>Welcome Back!</Text>
                        <form style={{ height: "100%", width: "100%" }} onSubmit={submitLogin}>
                            <Text textAlign={"start"} mt={{ base: 3, lg: 10 }} fontSize={{ base: 14, md: 18 }} fontWeight={"semibold"}>Enter Registered Mobile Number</Text>
                            <Text textAlign={"start"} fontSize={13}>Please enter correct registered details</Text>
                            <FormControl isRequired>
                                <Input
                                    mt={{ base: 5, sm: 10 }}
                                    size={{ base: "sm", sm: "md" }}
                                    type="email"
                                    fontSize={14}
                                    fontWeight={"semibold"}
                                    isRequired
                                    placeholder={"Email"}
                                    focusBorderColor='blue.400'
                                    value={inputValue?.email}
                                    onChange={(e) => inputHandler("email", e.target.value)}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <Input
                                    mt={4}
                                    size={{ base: "sm", sm: "md" }}
                                    type="password"
                                    fontSize={14}
                                    fontWeight={"semibold"}
                                    isRequired
                                    placeholder={"Password"}
                                    focusBorderColor='blue.400'
                                    value={inputValue?.password}
                                    onChange={(e) => inputHandler("password", e.target.value)}
                                />
                            </FormControl>
                            <Button w={"100%"} size={{ base: "sm", sm: "md" }} mt={5} type={"submit"} isLoading={userLoginStatus === STATUS.FETCHING} colorScheme={"blue"} >LogIn</Button>
                        </form>
                    </Flex>
                </Box>
            </Flex>
        </Flex >
    )
}